.zipcode-search {
  /* margin: 2rem auto 1rem; */
  width: max-content;
  margin-top: 0.25rem;
}

.zipcode-search-mini {
  margin: 1rem auto;
  width: max-content;
}

.zipcode-inputs {
  /* height: 1.5rem; */
  padding: 0.35rem;
}

.zipcode-button {
  margin-left: 0.25rem;
}

.weather {
  padding: 1rem;
  color: rgb(37, 37, 37);
}

.weather-loader {
  margin-top: 15rem;
}

.weather-flex {
  display: flex;
}

.weather-left {
  max-width: 40%;
}

.weather-today {
  margin: 0.5rem;
  /* border: 3px solid rgb(150, 176, 255); */
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  /* max-width: 20%; */
  border: solid 2px lightgrey;
}

.weather-today-thingy {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.today-icon {
  height: 6rem;
  position: relative;
  filter: brightness(20%);
  /* top: -1rem; */
}

.today-temp {
  font-size: 3rem;
  margin-left: 0.5rem;
}

.today-info {
  padding: 2rem;
  display: flex;
  flex-wrap: end;
  justify-content: space-between;
}

.arrow-icon {
  height: 2rem;
  margin-top: 1rem;
}

.daily-forecast-cont {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: white;
  border: solid 2px lightgrey;
}

.weather-forecast {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.weather-forecast-item {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 2px solid whitesmoke;
  border-radius: 0.5rem;
  width: 100%;
  border: solid 2px lightgrey;
}

.forecast-temp {
  /* background-color: blue; */
  /* text-align: center; */
}

.forecast-icon {
  width: 3rem;
  margin-bottom: 0;
  filter: brightness(30%);
}

.hourly-forecast-cont {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: white;
  border: solid 2px lightgrey;
}

.hourly-title {
  margin-left: 0.5rem;
}

.hourly-forecast {
  display: flex;
  justify-content: space-between;
}

.hourly-forecast-item {
  margin: 0.5rem 0.25rem;
  padding: 0.5rem;
  border: solid 2px lightgrey;
  border-radius: 0.5rem;
  /* width: 100%; */
}

.weather-disabled {
  width: 34%;
  margin: 4rem auto;
}

.location {
  /* width: 30rem; */
  /* margin: auto; */
}

.units-button {
  /* float: right; */
  /* padding: 0.2rem; */
  margin-left: 5rem;
}

.franja-data-flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: solid 2px lightgrey;
}

.weather-big-flex {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
}
