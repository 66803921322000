.grow:hover {
  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.grow {
  -webkit-animation: scale-up-center-out 0.3s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center-out 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@-webkit-keyframes scale-up-center-out {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center-out {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.pointer:hover {
  cursor: pointer;
}

.dim-shadow {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.37);
}

.darken:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.btn {
  margin-right: 2rem;
  background-color: white;
  padding: 0.5rem;
  border: 2px solid darkgrey;
}

.nolink {
  text-emphasis: none !important;
}

a {
  text-emphasis: none !important;
}
