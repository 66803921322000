.listing-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.article {
  margin: 1rem;
}

.article-img {
  height: 8rem;
  width: 12rem;
  border-radius: 3px;
}

.article-title {
  width: 12rem;
}

.news-category-select {
  width: 12rem;
  margin: 1.5rem auto 2rem auto;
  z-index: 1000;
}

.latest-news-title {
  font-size: 2.5rem;
  margin-bottom: 1rem auto;
  color: rgb(25, 25, 25);
  text-align: center;
}
