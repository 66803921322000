.navbar {
  background-color: #a6cef4;
  display: flex;
  border-bottom: 1px solid lightgrey;
}

.flex-item {
  text-align: center;
  padding: 0.25rem 0;
  width: 12rem;
  border-right: 1px solid lightgrey;
}
