.header {
  background: #62a9eb;
  /* background: linear-gradient(90deg, #5d97c7 100% #4380bb 35%); */
  color: white;
}

.flex-cont {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.logo {
  padding: 1rem;
  height: 7rem;
}

.dead-center {
  display: flex;
  justify-content: center;
}

.headline {
  padding: 1rem;
  text-align: center;
  /* max-width: 60%; */
}

.headline-title {
  text-decoration: underline;
  margin-top: 0.5rem;
  font-weight: bold;
  color: #1a0dab;
}

.far-right {
  padding: 1rem;
}

.weather-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.weather-img {
  height: 5rem;
  filter: brightness(0) invert(1);
}

.weather-widget-error {
  max-width: 20rem;
  float: right;
}