.swiper-container {
  width: 500px;
  height: 300px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
}
.gallery-container .main-gallery {
  padding-right: 15px;
  padding-left: 15px;
}

.thumbs-title {
  position: relative;
  bottom: 0;
  right: 15px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  text-shadow: 0 0 20px rgba(#000, 0.3);
  /* color: #ffffff; */
  text-overflow: ellipsis;
}

/* TODO: Long titles might touch the bottom of the image */
.gallery-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  max-width: 60%;
  max-height: 25%;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  text-shadow: 4px 4px 5px black;
  color: #ffffff;
  overflow: wrap;
  text-overflow: ellipsis;
}

.swiper-wrapper {
  padding-inline-start: 0;
  width: 400px;
  height: 300px;
}

.swiper-pagination {
  bottom: 0;
  padding-bottom: 10px;
}

.swiper-slide-thumb-active {
  background-color: rgb(195, 195, 195);
  height: 500px;
}

.swiper-slide:hover {
  cursor: pointer;
}

.carousel-article-image {
  height: 22rem;
}

.carousel-slides {
  margin-top: 1rem;
  width: 100%;
}

li {
  list-style-type: none;
}

.gallery-thumbs {
  width: 40rem;
}

.swiper-slide {
  width: 40rem;
}

.swiper-slide > .thumbs-title {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery-thumbs .swiper-slide {
  border-bottom: 1px solid #e9e9e9;
}

.gallery-thumbs {
  /* min-width: 0; */
  border-left: 1px solid #e9e9e9;
  flex: 2;
  position: relative;
  margin-right: 15px;
  padding-left: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.swiper-thumbs {
  margin-left: 0;
  width: 100%;
}

.gallery-container {
  /* background-color: green; */
  position: relative;
  width: min-content;
  margin: -1rem auto 1rem auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 330px;
}

.gallery-container .main-gallery {
  flex: 1;
}

.overflow-ellipsis {
  text-overflow: "ellipsis";
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  pointer-events: painted;
}

.gallery-container .swiper-button-prev {
  position: absolute;
  left: 0;
}

.gallery-container .swiper-button-next {
  position: absolute;
  right: 0;
}

.gallery-container .swiper-button-prev,
.gallery-container .swiper-button-next {
  height: 75px;
  width: 40px;
  color: #e9e9e9;
  background: #1e1e1e;
}
.gallery-container .swiper-button-prev::after,
.gallery-container .swiper-button-next::after {
  font-size: 12px;
}

.gallery-main .swiper-button-prev::after,
.gallery-main .swiper-button-next::after {
  font-size: 12px;
}
.swiper-slide-active .gallery-title {
  animation: slide-in 0.3s ease-in both;
}
.gallery-thumbs .swiper-slide,
img {
  transition: 0.3s;
}
/* .gallery-thumbs .swiper-slide-active {
  opacity: 0.5;
} */
.gallery-thumbs .swiper-slide-thumb-active {
  /* opacity: 0.5; */
  /* margin-left: 15px; */
}

.swiper-img {
  border-radius: 0.25rem;
}

.swiper-img-thumb {
  border-radius: 0.1rem;
}
