@media only screen and (max-width: 1000px) {
  .carrousel-display {
    display: none;
  }
  .category-selector {
    display: none;
  }
  .flex-item {
    width: 50%;
  }
  .article-img {
    height: auto;
  }
  .zipcode-search {
    width: auto;
  }
  .weather-big-flex {
    display: block;
    padding: 0;
  }
  .weather-left {
    max-width: 100vh !important;
  }
  .logo {
    margin: auto;
  }
  .dontshow {
    display: none;
  }
  .weather-forecast {
    display: block;
    margin: auto;
  }
  .article {
    width: auto;
    margin: 0;
    margin-bottom: 2rem;
  }
  .article-title {
    width: auto;
  }
  .article-img {
    width: 100%;
  }
  .franja-data-flex {
    display: block;
  }
  .location {
    text-align: center;
  }
  .zipcode-search {
    display: block;
    margin-top: 1rem;
  }
  .units-button {
    margin: 1rem auto;
  }
}
