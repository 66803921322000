.loader {
  border: 5px solid #00000000;
  border-radius: 50%;
  border-top: 5px solid #2e159d4b;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.home-loader {
  border: .5rem solid #00000000;
  border-top: .5rem solid #2e159d4b;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
  margin: 10rem auto;
}
