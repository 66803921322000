html {
  margin: 0;
  padding: 0;
  font-family: "Graphik";
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: Graphik;
  src: url("../assets/GoogleSans.ttf") format("opentype");
}

