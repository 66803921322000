.category-selector {
  /* width: 10rem; */
  position: absolute;
  right: 0;
  z-index: 9999;
  border-top: 1px solid lightgrey;
  background-color: gray;
}

.category-button {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  padding: 0.25rem;
  background-color: white;
  height: 28px;
}

.category-selected {
  background-color: lightgrey;
  right: 0.5rem;
  position: relative;
}
